<template>
  <div>
    <XDataTable
        :headers="headers"
        new-button
        title="MSISDN"
        item-name="MSISDN"
        :items-request="sipSubscriber.getSipSubscribersTable"
        :crud-requests="crudRequests"
        :item="item"
        :default-item="createDefaultItem()"
        :dialog-loading="dialogLoading"
        search
        @update:item="item = $event"
        dialog-width="541">
      <template #dialog-form>
        <div class="sip-subscribers-form">
          {{item.description}}
          <XTextField v-model="item.name" label="Name" required validate-immediately/>
          <XTextarea v-model="item.description" label="Description"/>
          <XTextField v-model="item.mcccmnc" label="MCC MNC"/>
        </div>
      </template>
    </XDataTable>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable';
import XTextField from '@/components/basic/XTextField';
import XTextarea from '@/components/basic/XTextarea';
//import HeadlineBox from '@/components/basic/HeadlineBox';
//import XCheckboxGroup from '@/components/basic/XCheckboxGroup';
//import XRadioGroup from '@/components/basic/XRadioGroup';
//import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import sipSubscriberService from "@/js/services/SipSubscriberService";
import sipSubscriber from "@/js/services/SipSubscriberService";

export default {
  name: 'SipSubscribers',
  components: {
     //XRadioGroup,
    //XCheckboxGroup,
   // HeadlineBox,
     XTextarea,
    XTextField,
    XDataTable,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Description',
          value: 'description',
          sortable: true,
        },
      ],
      item: this.createDefaultItem(),
      dialogLoading: false,
    };
  },
  computed: {
    sipSubscriber() {
      return sipSubscriber
    },
    crudRequests() {
      return {
        get: {
          request: sipSubscriberService.getSipSubscriber,
        },
        create: {
          //request: sipSubscriberService.createSipSubscriber,
        },
        update: {
         // request: sipSubscriberService.updateSipSubscriber,
        },
        delete: {
          request: sipSubscriberService.deleteSipSubscriber,
        },
      };
    },
  },
  methods: {
    createDefaultItem() {
      return {
        id: 0,
        name: '',
        description: '',
      };
    },
  },
};
</script>

<style scoped>
.sip-subscribers-form,
.sip-parameters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sip-parameters-bottom {
  display: flex;
  gap: 20px;
}

.resource-as {
  padding-top: 24px;
}
</style>